import { Vue, Component } from "vue-property-decorator";
import { IRequest, IRequestDatatable, ISnakeApplication } from "@/models";
import { RequestDatatable } from "@/components";

@Component({
    components: {
        RequestDatatable,
    },
    metaInfo: {
        title: "My requests",
    },
})

export default class RequestsUser extends Vue {

    public loading = false;

    public headers = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "nowrap", value: "snakeAppName", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Description", value: "description", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "Requested on", value: "requestedOn", class: "nowrap", cellClass: "nowrap" },
        { text: "", value: "actions", sortable: false },
    ];

    public userRequests: IRequestDatatable[] = [];

    async mounted(): Promise<void> {
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadUserRequests").then(async (data)=>{
                this.userRequests = data;
                await this.$store.dispatch("app/loadSnakeApps", data.map(r => r.snakeId)).then((snakeApps)=>{
                    this.userRequests.forEach(ur => {
                        const snakeApp = snakeApps.find(sa => sa.snakeId === ur.snakeId);
                        if(snakeApp){
                            ur.snakeAppId = snakeApp.snakeId;
                            ur.snakeAppName = snakeApp.general.name;
                            ur.snakeAppLogo = snakeApp.general.logo;
                            ur.snakeAppIsPlatform = snakeApp.general.isPlatform;
                        }
                    });
                });
            });

            await this.$store.dispatch("app/loadRequests");            
            await this.$store.dispatch("app/loadSnakeAppsByOwner", this.userEmail).then(async (data)=>{
                if(data !== null){
                    this.$store.getters["app/requests"].forEach(request => {
                        data.forEach(snake =>{
                            if(request.snakeId === snake.snakeId && this.userRequests.filter(r => r.id == request.id).length == 0){
                                this.userRequests.push(this.buildRequestDatatable(request, snake));
                            }
                        });
                    });
                }
            });
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }

    get userEmail(): boolean {
        return this.$store.getters["account/userEmail"];
    }

    private buildRequestDatatable(request: IRequest, snakeApp: ISnakeApplication): IRequestDatatable {
        return {
            id: request.id,
            description: request.description,
            client: request.client,
            snakeId: request.snakeId,
            snakeAppId: snakeApp.snakeId,
            snakeAppName: snakeApp.general.name,
            snakeAppLogo: snakeApp.general.logo,
            snakeAppIsPlatform: snakeApp.general.isPlatform,
            requestedBy: request.requestedBy,
            requestedOn: request.requestedOn,
            status: request.status,
            fields: request.fields,
            scopeAffiliates: request.scopeAffiliates,
            scopeCompanies: request.scopeCompanies,
            requestApprovals: request.requestApprovals
        };
    }
}


